<template>
  <div class="box-content">
    <div class="d-flex">
      💡
      <div class="ml-3">
        <h6>Ops, Página não encontrada</h6>
        <p>Infelizmente esta página não foi localizada no sistema.</p>

        <BaseButton
          variant="black"
          class="lista-produtos mt-4"
          @click="toRouter('/')"
        >
          Retornar para a página inicial
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-not-found",
  methods: {
    toRouter(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
h6 {
  font-weight: 600;
}
.box-content {
  margin-top: 40px;
  background: #fff;
  background: #f7f7f7;
  border-radius: 10px;
  padding: 40px;
  padding-right: 80px;
  max-width: 968px;
}
</style>
